/* eslint-disable no-console */
import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
// import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';
import Img from 'gatsby-image';
import Navigation from '../components/Nav';
import NavigationEn from '../components/Nav.en';
import Footer from '../components/Footer';

import '../style/app.scss';

export const TagData = ({ data }) => (
	<div>
		{data.allWordpressPost.nodes.map((post) => {
			const arrayOfWords = post.excerpt.split(' ', 50);
			const arrayOfWordsJoined = arrayOfWords.join(' ');
			return (
				<article key={post.id}>
					<ScrollAnimation animateIn="fadeInUp" animateOnce>
						<div className="featured_image">
							<Img
								imgStyle={{ height: 600, objectPosition: 'center' }}
								fluid={post.featured_media.localFile.childImageSharp.fluid}
							/>
						</div>
					</ScrollAnimation>

					<div className="meta">
						<div className="hashtags">
							{post.categories
								? post.categories.map((singleTag, index) => (
										<span key={index}>
											<a className="tagLink" href={`/tag/${singleTag.slug}/`}>
												#{singleTag.name}
											</a>
										</span>
								  ))
								: ''}
						</div>
					</div>

					<div className="info">
						<Link to={`/${post.slug}`} className="permalink">
							<h3 className="postTitle">{post.title}</h3>
						</Link>
						<div className="bottomContent">
							{
								// Check if under 20 wordss
								arrayOfWords.length < 50 ? (
									<div
										dangerouslySetInnerHTML={{
											__html: arrayOfWordsJoined,
										}}
										className="excerpt"
									/>
								) : (
									<div
										dangerouslySetInnerHTML={{
											__html: `${arrayOfWordsJoined} ...`,
										}}
										className="excerpt"
									/>
								)
							}

							<Link to={`/${post.slug}`} className="permalinkBottom">
								Read more
								{/* <FontAwesomeIcon icon={faChevronRight} /> */}
							</Link>
						</div>
					</div>
				</article>
			);
		})}
	</div>
);

TagData.propTypes = {
	data: PropTypes.object,
};

const TagPost = ({ data }) => {
	const { allWordpressPost: post } = data;
	return (
		<>
			<NavigationEn />
			{/* <div className="hero_single">
 					<div className="container">
 						<div className="content">
 							<h1>Pocetna</h1>
 							<Link to="/" className="back_home">
 								Nazad na početnu
 							</Link>
 						</div>
 					</div>
 				</div> */}
			<section className="blog tags">
				<div className="container fullWidthContainer">
					<div className="blog_grid tag_grid">
						<TagData data={data} />
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
};

TagPost.propTypes = {
	data: PropTypes.object.isRequired,
};

export default TagPost;

export const pageQuery = graphql`
	query CategoryPage($slug: String!) {
		allWordpressPost(filter: { categories: { elemMatch: { slug: { eq: $slug } } } }) {
			nodes {
				featured_media {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				categories {
					name
					slug
				}
				wordpress_id
				date
				excerpt
				slug
				title
				id
			}
		}
	}
`;
